export const dimensions = {
  pageWidth: 230,
  pageHeight: 297,
  pagePadding: 43,
  imageBleed: 3,
  pageBleed: 5,
  pageSlug: 10,
  workspacePaddingX: 460,
  stickerWidth: 50,
  stickerHeight: 70,
  stickerBleed: 3,
  gridSize: 5,
  spreadHighlightSize: 5,
};

export const itemTypes = {
  element: 'element',
  section: 'section',
  sticker: 'sticker',
  template: 'template',
  image: 'image',
  spread: 'spread',
};

export const imageTypes = {
  common: 'common',
  title: 'title',
  barcode: 'barcode',
  filler: 'filler',
  background: 'background',
  ad: 'ad',
  logo: 'logo',
  sponsor: 'sponsor',
  sponsorlogo: 'sponsorlogo',
  sticker: 'sticker',
};

export const imageTypeLabels = {
  common: 'Allgemein',
  title: 'Titelbild',
  barcode: 'Barcode',
  filler: 'Füllseiten',
  background: 'Hintergrund',
  ad: 'Anzeige',
  logo: 'Vereinslogo',
  sponsor: 'Marktbild',
  sponsorlogo: 'Marktlogo',
  sticker: 'Sticker',
};

// Also used for templates preview
export const assetPreviewSizeLabels = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  detail: 'Detail',
};

export const stickerFieldLabels = {
  name: 'Vor- und Nachname',
  position: 'Position',
};

export const alignDirection = {
  start: 'start',
  center: 'center',
  end: 'end',
};

export const alignTypes = {
  page: 'page',
  spread: 'spread',
  selection: 'selection',
  keyObject: 'keyObject',
};

export const alignTypeLabels = {
  page: 'Seite',
  spread: 'Doppelseite',
  selection: 'Auswahl',
  keyObject: 'Oberstes Objekt',
};

// These fields need to match existing album JSON, so they can not be camelized
export const templateTypes = {
  common: 'common',
  sticker: 'sticker',
  sticker_slot: 'sticker_slot',
  sticker_double: 'sticker_double',
  sticker_slot_double: 'sticker_slot_double',
  front: 'front',
  toc: 'toc',
  sts_greet: 'sts_greet',
  sticker_background: 'sticker_background',
  sticker_placement: 'sticker_placement',
  ad_full: 'ad_full',
  ad_combi: 'ad_combi',
  sponsorpage: 'sponsorpage',
  imprint: 'imprint',
  pagenums: 'pagenums',
};

// These fields need to match existing album JSON, so they can not be camelized
export const templateTypeLabels = {
  common: 'Allgemein',
  sticker: 'Sticker',
  sticker_slot: 'Sticker-Fläche',
  sticker_double: 'Doppelsticker',
  sticker_slot_double: 'Doppelsticker-Fläche',
  front: 'Titelseite',
  toc: 'Vereins-Grußwort',
  sts_greet: 'Stickerstars-Grußwort',
  sticker_background: 'Sticker-Hintergrund',
  sticker_placement: 'Sticker-Anordnung',
  ad_full: 'Ganzseitige Anzeige',
  ad_combi: 'Kombi-Anzeigenseite',
  sponsorpage: 'Marktvorstellung',
  imprint: 'Impressum',
  pagenums: 'Seitenzahlen',
};

// These fields need to match existing album JSON, so they can not be camelized
export const symbolTypes = {
  section: 'Name des Teams',
  album: 'Name des Albums',
  pagenum: 'Seitenzahl',
  totalpages: 'Gesamtseitenzahl',
  totalstickers: 'Gesamtstickeranzahl',
  toc_sections: 'Inhaltsverzeichnis / Teams',
  toc_pages: 'Inhaltsverzeichnis / Seiten',
  greeting_title: 'Grußwort-Titel',
  greeting_text: 'Grußwort-Text',
  market_presentation_text: 'Marktvorstellung-Text',
  market_presentation_profile: 'Marktvorstellung-Profil',
  market_presentation_did_you_know: 'Marktvorstellung-Wußtest Du...',
  'sticker-line': 'Sticker: Vor- und Nachname',
  'sticker-line1': 'Sticker: Vorname',
  'sticker-line2': 'Sticker: Nachname',
  'sticker-position1': 'Sticker: Position',
  'sticker-position2': 'Sticker: Position (2. Zeile, optional)',
  'sticker-number': 'Sticker: Nummer',
  'sticker-nextnumber': 'Sticker: 2.Nummer (Doppelsticker)',
};

export const renderModes = {
  album: 'Album',
  spread: 'Album-Doppelseiten',
  cover: 'Cover',
  sticker: 'Sticker',
};

export const renderFormats = {
  pdf: 'PDF',
  jpg: 'JPGs',
  png: 'PNGs',
};

export const colorValues = {
  selection: '#a1f6ff',
  spreadInStickerMode: '#333',
  spread: '#fff',
  background: '#272727',
  backgroundIsolation: '#a1f6ff',
  stickerstarsBrandRed: '#e0004d',
};

export const faceAlign = {
  width: 0.36,
  widthKids: 0.34,
  kidsAgeThreshold: 14,
  top: 0.32,
};

export const resolutions = {
  small: 'small',
  medium: 'medium',
  full: 'full',
  original: 'original',
};

// Arbitrarily maps zoom levels to image resolutions
export const resolutionZoomThresholds = [
  [3, resolutions.original],
  [2.2, resolutions.full],
  [1, resolutions.medium],
  [0, resolutions.small],
];

export const keys = {
  space: ' ',
  left: 'ArrowLeft',
  right: 'ArrowRight',
  up: 'ArrowUp',
  down: 'ArrowDown',
};

export const stickerColorIndex = 100;

export const appearanceInputModes = {
  stroke: 'stroke',
  fill: 'fill',
  fillAndBlend: 'fillAndBlend',
  opacity: 'opacity',
};

export const blendModes = [
  // supported by prince:
  'normal',
  'multiply',
  'screen',
  'darken',
  'lighten',

  // custom
  'luminosity',

  // not supported by prince:
  // 'overlay',
  // 'color-dodge',
  // 'color-burn',
  // 'hard-light',
  // 'soft-light',
  // 'difference',
  // 'exclusion',
  // 'hue',
  // 'saturation',
  // 'color',
  // 'luminosity',
];

export const operations = {
  move: 'moveOperation',
  rotate: 'rotateOperation',
  resize: 'resizeOperation',
  scale: 'scaleOperation',
};

/* These `actions` allows to reference certain redux-actions by string. They are also used in the `actionsMeta`-constant
and `getApplicableActions` selector. All actions have in common that they don't expect any parameter but instead
get their targeted items from the `selection` (via `getState`). These actions can be executed using `executeActionByName`.
 */
export const actions = {
  createSpreadBefore: 'createSpreadBefore',
  createSpreadAfter: 'createSpreadAfter',
  moveSpreadItemsUp: 'moveSpreadItemsUp',
  moveSpreadItemsDown: 'moveSpreadItemsDown',
  deleteSpreadItems: 'deleteSpreadItems',
  duplicateItems: 'duplicateItems',
  convertToEditableText: 'convertToEditableText',
  sendItemsFront: 'sendItemsFront',
  sendItemsBack: 'sendItemsBack',
  deleteElementItems: 'deleteElementItems',
  groupItems: 'groupItems',
  ungroupItems: 'ungroupItems',
  fitContentContain: 'fitContentContain',
  fitContentCover: 'fitContentCover',
  fitFrame: 'fitFrame',
  createTemplateFromGroup: 'createTemplateFromGroup',
  updateAllInstancesFromInstance: 'updateAllInstancesFromInstance',
  updateTemplateFromInstance: 'updateTemplateFromInstance',
  convertToLocalColors: 'convertToLocalColors',
  resetSpreads: 'resetSpreads',
  buildSpreads: 'buildSpreads',
  flipImage: 'flipImage',
  scaleStickersIn: 'scaleStickersIn',
  scaleStickersOut: 'scaleStickersOut',
  clearStickerLogo: 'clearStickerLogo',
  zoomSticker: 'zoomSticker',
  autoAlignSticker: 'autoAlignSticker',
  uploadSticker: 'uploadSticker',
  downloadSticker: 'downloadSticker',
  zoomSection: 'zoomSection',
  selectStickersInSection: 'selectStickersInSection',
  historyUndo: 'historyUndo',
  historyRedo: 'historyRedo',
  selectAll: 'selectAll',
  toggleEnableFilter: 'toggleEnableFilter',
  toggleCropPreview: 'toggleCropPreview',
  toggleStickerMode: 'toggleStickerMode',
  toggleGridEnabled: 'toggleGridEnabled',
  toggleAutoFit: 'toggleAutoFit',
  toggleShowElementsTree: 'toggleShowElementsTree',
};

/**
 * Returns an array of arrays, each element representing an action:
 *   [0] itemType or null for global actions
 *   [1] action to be called
 *   [2] label or null
 *   [3] hotkeys as array of objects or null
 *   [4] icon-name
 */
export const actionsMeta = [
  // Spread
  [itemTypes.spread, actions.deleteSpreadItems, 'Delete spread', null, 'trash'],
  [itemTypes.spread, actions.createSpreadBefore, 'Insert spread before', null],
  [itemTypes.spread, actions.createSpreadAfter, 'Insert spread after', null],
  [
    itemTypes.spread,
    actions.moveSpreadItemsUp,
    'Move spread up',
    null,
    'angle up',
  ],
  [
    itemTypes.spread,
    actions.moveSpreadItemsDown,
    'Move spread down',
    null,
    'angle down',
  ],

  // Element
  [
    itemTypes.element,
    actions.deleteElementItems,
    'Delete elements',
    [{ key: 'Delete' }, { key: 'Backspace' }],
    'trash',
  ],
  [
    itemTypes.element,
    actions.createTemplateFromGroup,
    'Create template from group',
    null,
    'file image outline',
  ],
  [
    itemTypes.element,
    actions.updateAllInstancesFromInstance,
    'Update all instances',
    null,
    'refresh',
  ],
  [
    itemTypes.element,
    actions.updateTemplateFromInstance,
    'Update template',
    null,
    'save',
  ],
  [
    itemTypes.element,
    actions.convertToLocalColors,
    'Convert swatch colors to local colors',
    null,
  ],
  [
    itemTypes.element,
    actions.sendItemsFront,
    'Send to front',
    null,
    'arrow up',
  ],
  [
    itemTypes.element,
    actions.sendItemsBack,
    'Send to back',
    null,
    'arrow down',
  ],
  [
    itemTypes.element,
    actions.duplicateItems,
    'Duplicate',
    [{ key: 'd', ctrlKey: true }],
    'copy',
  ],
  [
    itemTypes.element,
    actions.convertToEditableText,
    'Convert to editable text',
    null,
    'text cursor',
  ],
  [
    itemTypes.element,
    actions.groupItems,
    'Group',
    [{ key: 'g', ctrlKey: true, shiftKey: false }],
    'object group',
  ],
  [
    itemTypes.element,
    actions.ungroupItems,
    'Ungroup',
    [
      { key: 'G', ctrlKey: true, shiftKey: true },
      { key: 'u', ctrlKey: true },
    ],
    'object ungroup',
  ],
  [
    itemTypes.element,
    actions.fitContentCover,
    'Fit content cover',
    null,
    'compress',
  ],
  [
    itemTypes.element,
    actions.fitContentContain,
    'Fit content contain',
    null,
    'expand',
  ],
  [itemTypes.element, actions.fitFrame, 'Fit frame to content', null],
  [
    itemTypes.element,
    actions.flipImage,
    'Flip image horizontally',
    null,
    'resize horizontal',
  ],

  // Sticker
  [
    itemTypes.sticker,
    actions.scaleStickersIn,
    'Sticker vergrößern',
    [{ key: '.', ctrlKey: true, shiftKey: false }],
    'zoom in',
  ],
  [
    itemTypes.sticker,
    actions.scaleStickersOut,
    'Sticker verkleinern',
    [{ key: ',', ctrlKey: true, shiftKey: false }],
    'zoom out',
  ],
  [
    itemTypes.sticker,
    actions.clearStickerLogo,
    'Sticker-Logo entfernen',
    null,
    'delete',
  ],
  [itemTypes.sticker, actions.zoomSticker, 'Zoom auf Sticker', null, 'find'],
  [
    itemTypes.sticker,
    actions.autoAlignSticker,
    'Sticker automatisch ausrichten',
    null,
    'crosshairs',
  ],
  [
    itemTypes.sticker,
    actions.uploadSticker,
    'Sticker ersetzen',
    null,
    'upload',
  ],
  [
    itemTypes.sticker,
    actions.downloadSticker,
    'Sticker herunterladen',
    null,
    'download',
  ],
  [itemTypes.section, actions.zoomSection, 'Zoom auf Team'],
  [
    itemTypes.section,
    actions.selectStickersInSection,
    'Enthaltene Sticker auswählen',
  ],

  // Global
  [null, actions.historyUndo, 'Undo', [{ key: 'z', ctrlKey: true }]],
  [
    null,
    actions.historyRedo,
    'Redo',
    [{ key: 'Z', ctrlKey: true, shiftKey: true }],
  ],
  [null, actions.selectAll, null, [{ key: 'a', ctrlKey: true }]],
  [null, actions.toggleEnableFilter, null, [{ key: 'f' }]],
  [null, actions.toggleCropPreview, null, [{ key: 'w' }]],
  [null, actions.toggleStickerMode, null, [{ key: 's' }]],
  [null, actions.toggleGridEnabled, null, [{ key: 'g' }]],
  [null, actions.toggleAutoFit, null, [{ key: 'a' }]],
  [null, actions.toggleShowElementsTree, null, [{ key: 'e' }]],
];

export const fonts = [
  'Apis',
  'Apis-Light',
  'Apis-Black',
  'Gilroy',
  'Gilroy Light',
  'Gilroy Black',
  'Arial',
  'Arial Narrow',
  'Barlow',
  'Bradley Hand',
  'Cairo',
  'Cairo Light',
  'CaveatBrush',
  'Clearface Gothic LT Std',
  'Courier New',
  'Coolvetica',
  'Coolvetica-Condensed',
  'Coolvetica-Compressed',
  'Coolvetica-Crammed',
  'DCC-Ash',
  'DINPro',
  'DINPro-black',
  'DraegerSans',
  'DraegerSans Light',
  'DraegerWalbaum',
  'Edeka Chalk',
  'Exo2',
  'GlossAndBloom',
  'Graphik',
  'Graphik Black',
  'Graphik Light',
  'Graphik Medium',
  'Have Heart One',
  'Helvetica Neue',
  'Helvetica Neue Condensed',
  'HighVoltage Rough',
  'Hind',
  'ITC Avant Garde Gothic',
  'Kaufland',
  'Lato',
  'Lato-Black',
  'Mada-Light',
  'Mada',
  'Mada-Black',
  'Neuton',
  'Open Sans',
  'Open Sans Light',
  'Oswald',
  'Raleway',
  'Raleway-Light',
  'Realize My Passion',
  'Rewe Pop',
  'Roboto',
  'Rockness',
  'Rubik Light',
  'Rubik Medium',
  'Rubik',
  'Rubik Semibold',
  'Saira Condensed',
  'Saira Stencil',
  'Sean Becker-Heavy',
  'SedgwickAve',
  'Siemens Sans Global',
  'Tandelle',
  'TradeGothicLT',
  'TitilliumWeb',
];

export const fontSizes = [
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  36,
  40,
  48,
  56,
  64,
  72,
  80,
  96,
  128,
];

export const lineHeights = [
  '50%',
  '75%',
  '100%',
  '120%',
  '125%',
  '150%',
  '175%',
  '200%',
];

export const stickerPlacings = {
  compress: 'compress',
  expand: 'expand',
  grid: 'grid',
};

export const imageRatios = [
  {
    value: 's_p',
    text: 'S (1/16 Seite, hoch)',
    props: {
      width: 42,
      height: 60,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 's_l',
    text: 'S (1/16 Seite, quer)',
    props: {
      width: 60,
      height: 42,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'm_p',
    text: 'M (1/4 Seite, hoch)',
    props: {
      width: 90,
      height: 127,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'm_l',
    text: 'M (1/4 Seite, quer)',
    props: {
      width: 127,
      height: 90,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'l_p',
    text: 'L (1/2 Seite, hoch)',
    props: {
      width: 130,
      height: 184,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'l_l',
    text: 'L (1/2 Seite, quer)',
    props: {
      width: 184,
      height: 130,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'xl_border',
    text: 'XL (1/1 Seite, hoch)',
    props: {
      width: 190,
      height: 268,
      stroke: '#FFF',
      fill: 1,
      strokeWidth: 1,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'xl',
    text: 'XL (1/1 Seite, voll)',
    props: {
      width: dimensions.pageWidth + dimensions.pageBleed * 2,
      height: dimensions.pageHeight + dimensions.pageBleed * 2,
      rotation: 0,
      scale: 1,
    },
  },
  {
    value: 'xxl',
    text: 'XXL (2/1 Seite, voll)',
    props: {
      width: dimensions.pageWidth * 2 + dimensions.pageBleed * 2,
      height: dimensions.pageHeight + dimensions.pageBleed * 2,
      rotation: 0,
      scale: 1,
    },
  },
];

export const importSourceTypes = {
  appProject: 'app_project',
  editorAlbum: 'editor_album',
};

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_URL = `${API_BASE_URL}/v1`;
export const UPLOADS_URL = `${API_BASE_URL}/rails/active_storage/direct_uploads`;
export const APP_API_URL = 'https://app.stickerstars.de/api';

export const defaultStickerColor = '#ccc';
export const defaultCustomColor = '#888';

export const fontSizeScaleFactor = 0.1;
